import "./offerwalls.css"
import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { recLastActv } from "../../components/functions/RecLastActv";
import { useEffect } from "react";
import SeoTags from "../common/SeoTags";

const ToniqOfferWall = props => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const url = "https://toniq.kypi.io/offer?content_id=67a28c6758e1302f1441ca19&api_key=faafdd82-3330-4104-a6eb-0c291b2241f1&user_id=" + uid + "&gender=&age="

     useEffect(() => {
          if (uid) {
               recLastActv(uid, "Entrou ow Toniq")
          }
     }, [uid])

     return (
          <Fragment>
               <SeoTags
                    title="Toniq Offerwall"
                    description="Toniq Offerwall"
                    url="https://www.superbuxx.com/toniq"
               />
               <div className="mt-5">
                    <div className="offerwall">
                         <iframe
                              src={url}
                              style={{
                                   height: "100vh",
                                   width: "100%",
                                   margin: 0,
                                   padding: 0,
                                   border: 0,
                                   frameborder: 0
                              }}
                         >
                         </iframe>
                    </div>
               </div>
          </Fragment>
     )
}
export default ToniqOfferWall
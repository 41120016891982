import React, { useContext, useEffect } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Pagina1 from '../views/pagina1/Pagina1';
import UserAccount from '../views/useraccount/UserAccount';
import VerifyEmailMsg from '../views/common/VerifyMailMsg';
import UserHistory from '../views/userhistory/UserHistory';
import ExchangeBuxx from '../views/exchangebuxx/ExchangeBuxx';
import UserExchangeHistory from '../views/userexchangehistory/userexchangehistory';
import NotikOfferWall from '../views/offerwalls/notik';
import ToroxOfferWall from '../views/offerwalls/torox';
import AdgemOfferWall from '../views/offerwalls/adgem';
import RevuOfferWall from '../views/offerwalls/revu';
import AppSamuraiOfferwall from '../views/offerwalls/appsamurai';
import { AuthCredential } from 'firebase/auth';
import Swaarm from '../views/offerwalls/swaarm';
import VerifyCell from '../views/verifycell/verifycell';
import Notifications from '../views/notificacoes/Notifications';
import Ticket from '../views/ticket/Ticket';
import TicketsAdm from '../views/ticket/TicketsAdm';
import TicketError from '../views/ticket/TicketError';
import TicketReply from '../views/ticket/TicketReply';
import BrPaymentInfo from '../views/paymentinfo/BrPaymentInfo';
import UserPayPalInfo from '../views/paymentinfo/UserPaypalInfo';
import RevtooOfferwall from '../views/offerwalls/revtoo';
import MyChipsOfferwall from '../views/offerwalls/mychips';
import AdgateOfferwall from '../views/offerwalls/adgate';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import ToroxTest from '../views/categorias/Torox';
import TimewallOfferWall from '../views/offerwalls/timewall';
import LootablyOfferWall from '../views/offerwalls/lootably';
import ToniqOfferWall from '../views/offerwalls/toniq';


const RequireAuth = () => {

     const { usuario } = useContext(AuthContext);

     // Defina suas rotas privadas aqui
     const routes = useRoutes([
          { path: '/useraccount', element: <UserAccount /> },
          { path: '/verifyemail', element: <VerifyEmailMsg /> },
          { path: '/userhistory', element: <UserHistory /> },
          { path: '/exchangebuxx', element: <ExchangeBuxx /> },
          { path: '/userexchangehistory', element: <UserExchangeHistory /> },
          { path: '/notik', element: <NotikOfferWall />},
          { path: '/torox', element: <ToroxOfferWall />},
          { path: '/adgem', element: <AdgemOfferWall />},
          { path: '/revu', element: <RevuOfferWall />},
          { path: '/appsprize', element: <AppSamuraiOfferwall />},
          { path: '/swaarm', element: <Swaarm />},
          { path: '/verifycell', element: <VerifyCell />},
          { path: '/notifications', element: <Notifications />},
          { path: '/ticket/:offer_id', element: <Ticket />},
          { path: '/tickets', element: <TicketsAdm />},
          { path: '/ticketerror', element: <TicketError />},
          { path: '/ticketreply/:ticket_id', element: <TicketReply />},
          { path: '/brpayinfo', element: <BrPaymentInfo />},
          { path: '/paypalinfo', element: <UserPayPalInfo />},
          { path: '/revtoo', element: <RevtooOfferwall />},
          { path: '/mychips', element: <MyChipsOfferwall />},
          { path: '/adgate', element: <AdgateOfferwall />},
          { path: '/toroxtest', element:<ToroxTest/>},
          { path: '/timewall', element:<TimewallOfferWall/>},
          { path: '/lootably', element:<LootablyOfferWall/>},
          { path: '/toniq', element:<ToniqOfferWall/>},
     ]);

     return usuario == null ? <Navigate to="/login" /> : routes;
};

export default RequireAuth;
